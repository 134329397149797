import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import GRISU from '../images/GRISU.jpg';

const Headline = () => {
  return (
    <Container className="pt-3 pb-3 pb-lg-5 border-bottom" fluid>
      <Row>
        <Image alt="Grb grada Novigrada" src={GRISU} height={95} className='object-fit-contain' />
      </Row>
      <Row>
        <Col className="col-10 col-lg-8 mx-auto py-2">
          <label className="text-center font-weight-normal">DVD Neapolis sudjeluje u akcijama gašenja požara i spašavanja osoba i imovine, te promiče, razvija i unaprijeđuje zaštitu od požara i vatrogastvo u Gradu Novigradu</label>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto mx-auto pt-4 pb-3 pb-xl-0">
          <Link to={"/o-nama"}>
            <Button className="px-4 py-2 btn-danger"><b>{"Više o nama "}</b></Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
export default Headline