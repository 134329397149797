import React, { useEffect, useState } from 'react';
import { Image, Container, Row, Col, Card, Placeholder } from 'react-bootstrap';
import { GetGallery } from 'api/API';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import Breadcrumbs from './Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';

function Gallery() {
  const [galleryData, setGalleryData] = useState([]);

  //Current Page
  const location = useLocation();
  const { pathname } = location;
  const currentPage = pathname === "/" ? "/" : pathname.substring(1, pathname.length);
  useEffect(() => {
    async function loadData() {
      const fetchedGalleryData = await GetGallery();
      setGalleryData(fetchedGalleryData);
    }

    loadData();
  }, []);

  /* Ovo je STARO, NOVO je ispod -> Linije koda: 40 do 43

  {document.title = galleryData ? currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis" :
    (currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis")
  }

  */

  //Document title
  document.title = galleryData ?
    currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis" :
    currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis";
  
  const onInit = () => { };

  return (
    <Container className='px-0 mx-0 col-12' fluid>
      <Row className='ps-3'>
        <Row className='mt-4'>
          <Breadcrumbs subLinks={[]} />
        </Row>
        <Row className='h1 fw-bold mt-2'>
          Galerija
        </Row>
        
      </Row>
      {galleryData && galleryData.length > 0 ? (
        <Row className=".lg-container col-12 ps-0 pe-3 pe-xl-0 mx-0">
          {galleryData.map((single_gallery, index) => (
            <Row key={index} className="col-12 mt-3 px-0 mx-0">
              <Row className='h2 px-3'>
                {single_gallery.title.rendered}
              </Row>
              <Row className='px-0 mx-0'>
                <LightGallery
                  onInit={onInit}
                  speed={500}
                  plugins={[lgThumbnail, lgZoom]}
                  selector=".lg-item"
                  elementClassNames="px-0 mx-0"
                >
                  <Row className="App d-flex col-auto col-xl-12 ps-0">
                    {single_gallery._embedded['acf:attachment'] && single_gallery._embedded['acf:attachment'].map((image, imageIndex) => (
                      <Col key={imageIndex} className='col-12 col-md-6 col-xl-3 py-2 ps-3 pe-0 mt-1'>
                        <Link to={image.source_url} data-src={image.source_url} className="lg-item">
                          <Image alt={single_gallery.title.rendered} src={image.media_details.sizes.large.source_url} className='object-fit-cover w-100' height={225} rounded />
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </LightGallery>
              </Row>
            </Row>
          ))}
        </Row>
      ) : (
        <Row className="col-12 px-0 mx-0">
          <Row className='col-12 mt-3 mb-2 px-0 mx-0'>
            <Placeholder as={Card.Text} className="h2 px-1" animation="wave">
              <Placeholder xs={3} />
            </Placeholder>
          </Row>
          <Row className="d-flex col-12 px-0">
            {Array.from({ length: 12 }, (ele, index) => (
              <Col key={index} className='col-12 col-md-6 col-xl-3 py-2 ps-3 pe-0 mt-1 '>
                <Placeholder as={Card.Img} height={225} className='rounded w-100' />
              </Col>
            ))}
          </Row>
        </Row>
      )}
    </Container>
  );
}

export default Gallery;
