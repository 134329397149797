import React from 'react';
import { FiFacebook, FiMail, FiMapPin } from 'react-icons/fi';
import { Link } from "react-router-dom";
import { ListGroup } from 'react-bootstrap';
const { Container, Row, Col } = require("react-bootstrap");

/*  
*   INPUT: CENTERED (Boolean) - Defines the display usage of this component in it's parent
*     IF centered true -> Larger gap between Images and Justified in center (E.g. when using whole width to display in center of page)
*     ELSE -> Tiny gap between Images (E.g. when using on a side or corner of a container)
*/

const Social = ({ centered }) => {
  if (centered === "true") {
    return (
      <Container className="py-2" fluid>
        <Row className="py-4">
          <Col className="col-auto mx-auto">
            <ListGroup className="d-flex list-unstyled flex-row">
              <ListGroup.Item className="border-0">
                <Link to={"mailto:vatrogasci@dvd-neapolis.hr"} target="_blank" rel="noopener noreferrer">
                  <FiMail size={20} color="black" className='mx-1'/>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <Link to={"https://www.google.com/maps/place/Vigili+Del+Fuoco/@45.3196411,13.5689473,17.25z/data=!4m14!1m7!3m6!1s0x477c9bf37cffbf91:0x76d4b2e16b1810b2!2sNeapolis+Ltd.!8m2!3d45.315895!4d13.5611311!16s%2Fg%2F1tw1cp7z!3m5!1s0x477c9bed988373ad:0xe27a30f47a263464!8m2!3d45.3194237!4d13.5697309!16s%2Fg%2F11k54cxfxd?entry=ttu"} target="_blank" rel="noopener noreferrer">
                  <FiMapPin size={20} color="black" className='mx-1'/>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <Link to={"https://www.facebook.com/dvdcvvfneapolis.novigrad/"} target="_blank" rel="noopener noreferrer">
                  <FiFacebook size={20} color='black' className='me-1'/>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className="px-0 d-none d-xl-block">
        <Link to={"mailto:vatrogasci@dvd-neapolis.hr"} target="_blank" rel="noopener noreferrer">
          <FiMail size={20} color="black" className='ms-3' />
        </Link>
        <Link to={"https://www.google.com/maps/place/Vigili+Del+Fuoco/@45.3196411,13.5689473,17.25z/data=!4m14!1m7!3m6!1s0x477c9bf37cffbf91:0x76d4b2e16b1810b2!2sNeapolis+Ltd.!8m2!3d45.315895!4d13.5611311!16s%2Fg%2F1tw1cp7z!3m5!1s0x477c9bed988373ad:0xe27a30f47a263464!8m2!3d45.3194237!4d13.5697309!16s%2Fg%2F11k54cxfxd?entry=ttu"} target="_blank" rel="noopener noreferrer">
          <FiMapPin size={20} color="black" className='ms-3' />
        </Link>
        <Link to={"https://www.facebook.com/dvdcvvfneapolis.novigrad/"} target="_blank" rel="noopener noreferrer">
          <FiFacebook size={20} color='black' className='mx-2' />
        </Link>
      </Container>
    );
  }

}
export default Social