const Global = () =>{}

Global.localurl = "https://dvd-neapolis.hr/wordpress//wp-json/wp/v2/";
Global.APIkey = "AIzaSyA6o3GBQ1RZNUe-YCQq6ufpL8k9WKPOx5o";
Global.imagePlaceholder = "data:image/gif;base64,R0lGODlhAQABAPAAAPPz8wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";
Global.arrowR = "->";
Global.arrowL = "<-";
Global.emailServiceID = "service_email";
Global.emailTemplateID = "template_email";
Global.emailPublicKey = "P3cZkhP-FrV1JRtZP";
Global.localPageURL = "https://dvd-neapolis.hr/wordpress/index.php/wp-json/wp/v2/pages?_embed&slug="
Global.podrucjeURL = "https://dvd-neapolis.hr/wordpress/wp-json/wp/v2/media?parent=106"

export default Global