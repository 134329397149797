import axios from 'axios';

const WP_url = "https://dvd-neapolis.hr/wordpress/index.php/wp-json/wp/v2/";
const WP_pageURL ="https://dvd-neapolis.hr/wordpress/wp-json/wp/v2/pages?_embed&slug=";

//Fetch a list of all Categories
export const GetCategories = async () => {
  const result = await axios.get(WP_url + "categories");
  return result.data;
}

//Fetch all Galleries
export const GetGallery = async () => {
  const result = await axios.get(WP_url + 'galerija?_embed');
  return result.data;
}

//Fetch Single Post by Post's ID
export const GetSinglePost = async ({ PostID }) => {
  const result = await axios.get(WP_url + "posts/" + PostID + "?_embed");
  return result.data;
}

/*Fetch Posts based on input parameters
* INPUTS:
* quantity [integer] -> Number of posts to fetch per page
* order [boolean] -> Order Post's by creation date [TRUE = Descending / FALSE = Ascending]
* category_ID [integer] -> If defined = fetch posts only with that category ID, ELSE return all categories
* page [integer] -> Page number to fetch (for pagination)
* OUTPUT: Axios result object
*/
export const GetPosts = async ({ quantity, order, category_ID, page }) => {
  const url = WP_url +
    "posts?_embed&per_page=" + quantity +
    "&orderby=id&order=" + (order ? "desc" : "asc") +
    ((category_ID && category_ID !== null && category_ID !== "null") ? ("&categories=" + category_ID) : "") +
    ((page != null && page !== 0) ? ("&page=" + page) : "");

  const result = await axios.get(url);
  return result;
}

//Fetch Page "O NAMA"
export const GetPageOnama = async () => {
  const result = await axios.get(WP_pageURL + "o-nama");
  return result.data;
}