import Global from "config/Global";
import React from "react";
import { Col, Row, Image, Button, Placeholder, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

/*Display Single post for page News
* INPUT: Wordpress post
* OUTPUT: Post or skeleton/placeholder if input "post" is null 
*/
function SinglePost({ post }) {
  const sanitizer = DOMPurify.sanitize;

  return (
    <Col>
      {post ?
        <><Row className='border-bottom pb-3'>
          { 
            //Displaying post data
            <Col className={'col-12 ps-0 pe-3 pe-lg-4 py-4'}>
              <Image src={(post && post._embedded && post._embedded['wp:featuredmedia']) ?
                post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url :
                Global.imagePlaceholder} height={300} className='border rounded object-fit-cover w-100' rounded
              />
              <h3 className='pt-3'><b>{post.title.rendered}</b></h3>
              <p className="text-secondary">{post._embedded['wp:term'][0][0].name + " | " +
                new Date(post.date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
              <div dangerouslySetInnerHTML={{ __html: sanitizer(post.content.rendered) }} />
            </Col>
          }
        </Row>
          <Row>
            <Link to={"/novosti"} className='px-0 py-4'>
              <Button className="px-4 py-2 btn-danger"><b>{"Povratak na novosti"}</b></Button>
            </Link>
          </Row>
        </>
        :
        <>
          <Row className='border-bottom pb-3'>
            {
              //SKELETON / PLACEHOLDERS
              <Col className={'col-12 ps-0 pe-3 pe-lg-4 py-4'}>
                <Placeholder as={Card.Img} animation="wave" height={300} className='border rounded w-100' />
                <Placeholder as="h3" animation="wave" className='pt-3' >
                  <Placeholder className="col-5" />
                </Placeholder>
                <Placeholder as={Card.Text} animation="wave" className='text-secondary' >
                  <Placeholder className="col-3" />
                </Placeholder>
                <Placeholder as={Card.Text} animation="wave" >
                  <Placeholder className="col-2" /> <Placeholder className="col-5" /> <Placeholder className="col-3" />
                  <Placeholder className="col-4" /> <Placeholder className="col-4" /> <Placeholder className="col-3" />
                  <Placeholder className="col-6" /> <Placeholder className="col-5" />
                  <Placeholder className="col-2" /> <Placeholder className="col-3" /> <Placeholder className="col-5" />
                  <Placeholder className="col-4" /> <Placeholder className="col-2" />
                </Placeholder>
              </Col>
            }
          </Row>
          <Row>
            <Link to={"/novosti"} className='px-0 py-4'>
              <Button className="px-4 py-2 btn-danger"><b>{"Povratak na novosti"}</b></Button>
            </Link>
          </Row>
        </>
      }
    </Col>
  );
}

export default SinglePost