import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image, Nav, Pagination, Placeholder, Card } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import SinglePost from './SinglePost';
import Global from 'config/Global';
import DOMPurify from 'dompurify';
import { GetCategories, GetPosts, GetSinglePost } from 'api/API';
import Breadcrumbs from './Breadcrumbs';

const News = () => {
  const location = useLocation();
  const { pathname } = location;
  const currentPage = pathname === "/" ? "/" : pathname.substring(1, pathname.length);
  const sanitizer = DOMPurify.sanitize;
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId") ? Number(searchParams.get("categoryId")) : null;
  const [categories, setCategories] = useState([]);
  const postID = searchParams.get("postID");
  const [post, setSinglePost] = useState(null);
  const curent_page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [posts, setPosts] = useState([]);
  const [pageNum, setPageNumber] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    async function loadData() {
      const fetchedCategories = await GetCategories();
      setCategories(fetchedCategories);
      const fetchedPosts = await GetPosts({ quantity: 7, order: true, category_ID: categoryId, page: curent_page });
      setPosts(fetchedPosts.data);
      setPageNumber(fetchedPosts.headers["x-wp-totalpages"]);
      const fetchedLatestPosts = await GetPosts({ quantity: 5, order: true, category_ID: null, page: 0 });
      setLatestPosts(fetchedLatestPosts.data);
      const fetchedSinglePost = postID ? await GetSinglePost({ PostID: postID }) : null;
      setSinglePost(fetchedSinglePost);
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    loadData();
  }, [postID, categoryId, curent_page]);

  //Fetching document title
  document.title = post ? 
    post.title.rendered + " - " + currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis" :
    currentPage[0].toUpperCase() + currentPage.slice(1) + " - DVD Neapolis";
  
  //Pagination buttons "First page" and "Previous."
  let PaginationBTNs = [];
  if (curent_page > 1) {
    if (curent_page > 2) {
      PaginationBTNs.push(
        <Pagination.First key={"pp1"} href={"/novosti?categoryId=" + categoryId + "&page=1"}>
          {Global.arrowL + " Prva"}
        </Pagination.First>
      );
    }
    PaginationBTNs.push(
      <Pagination.Prev key={"pp2"} href={"/novosti?categoryId=" + categoryId + "&page=" + (curent_page - 1)}>
        {Global.arrowL + " Natrag"}
      </Pagination.Prev>
    );
  }

  //Pagination first and last visible numbers
  const paginFirstNum = (
    (curent_page > 3 && pageNum > 4) ?
      (pageNum - curent_page) < 2 ?
      (curent_page - 4) + (pageNum - curent_page) :
      (curent_page - 2)
    :
      1
  );
  const paginLastNum = (curent_page > 3 ? curent_page + 2 : 5);

  //Pagination numbers 
  for (let number = paginFirstNum; (number <= pageNum && number <= paginLastNum); number++) {
    PaginationBTNs.push(
      <Pagination.Item key={"p" + number} active={number === curent_page} href={"/novosti?categoryId=" + categoryId + "&page=" + number}>
        {number}
      </Pagination.Item>
    );
  }

  //Pagination buttons "Next" and "Last page"
  if (curent_page < pageNum) {
    PaginationBTNs.push(
      <Pagination.Next key={"pp3"} href={"/novosti?categoryId=" + categoryId + "&page=" + (curent_page + 1)}>
        {"Dalje " + Global.arrowR}
      </Pagination.Next>);
    if (curent_page + 1 < pageNum) {
      PaginationBTNs.push(
        <Pagination.Last key={"pp4"} href={"/novosti?categoryId=" + categoryId + "&page=" + pageNum}>
          {"Zadnja " + Global.arrowR}
        </Pagination.Last>
      );
    }
  }

  //BREADCRUMB
  const breadArray = [];
  if (postID) {
    breadArray.push(post ? post.title.rendered : "");
  } else {
    if (categoryId && categoryId != null) {
      if (categories) {categories.forEach(kate => {
        if (kate.id === categoryId) {
          breadArray.push(kate.name);
        }
      })} else {
        breadArray.push("");
      }
    }
  }

  return (
    <Container className="pt-4 pb-2" fluid>
      <Row>
        <Breadcrumbs subLinks={breadArray} />
      </Row>
      {
        //IF post not selected -> Display page header
        (!postID) ?
          <Row className="col-auto me-auto py-2 ps-0 h1 fw-bold">
            Novosti
          </Row>
          : 
          <></>
      }
      <Row>
        {
          //IF post is selected -> Display selected post
          //ELSE -> Display all posts (news)
          (postID) ?
            <SinglePost post={post} />
            :
            <Col>
              <Row className='border-bottom pb-3'>
                {posts.length > 0 ?
                  posts.map((post, index) => (
                    <Col key={post.id} className={(index === 0 ? 'col-12 ps-0 pe-2 pe-md-3 py-4' : 'col-12 col-md-6 ps-0 pe-3 pe-md-4 py-4')}>
                      <Link to={"/novosti?postID=" + post.id}>
                        <Image src={(post && post._embedded && post._embedded['wp:featuredmedia']) ?
                          index === 0 ?
                            post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url :
                            post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url :
                          Global.imagePlaceholder} className='border rounded object-fit-cover w-100 ' height={(index === 0 ? 300 : 200)} />
                      </Link>
                      <h5 className='pt-2'><b>{post.title.rendered}</b></h5>
                      <Container className="px-0 mx-0 pb-2">
                        <Row>
                          <Col className='col-auto pe-0'>{post._embedded['wp:term'][0][0].name}</Col>
                          <Col className='col-auto ps-1 text-secondary'>
                            {"| " + new Date(post.date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}
                          </Col>
                        </Row>
                      </Container>
                      <div dangerouslySetInnerHTML={{ __html: (sanitizer(post.content.rendered)).substring(0, (index === 0 ? 400 : 250)) + "..."}} />
                      <Link to={"/novosti?postID=" + post.id}>
                        <Button className="px-4 py-2 btn-danger"><b>{"Pročitaj više "}</b></Button>
                      </Link>
                    </Col>
                  ))
                  :
                  <>
                    {
                      //SKELETON / PLACEHOLDER for all posts
                      Array.from({ length: 3 }, ((ele, index) => (
                        <Col key={index} className={(index === 0 ? 'col-12 ps-0 pe-2 pe-md-3 py-4' : 'col-12 col-md-6 ps-0 pe-3 pe-md-4 py-4')}>
                          <Placeholder as={Card.Img} animation="wave" height={(index === 0 ? 300 : 200)} className='border rounded w-100' />
                          <Placeholder as="h5" animation="wave" className='pt-2' >
                            <Placeholder className="col-5" />
                          </Placeholder>
                          <Placeholder as={Card.Text} animation="wave" className='mb-2' >
                            <Placeholder className="col-2" /> <Placeholder className="text-secondary col-2" />
                          </Placeholder>
                          <Placeholder as={Card.Text} animation="wave" className='' >
                            <Placeholder className="col-2" /> <Placeholder className="col-5" /> <Placeholder className="col-3" />
                            <Placeholder className="col-4" /> <Placeholder className="col-4" /> <Placeholder className="col-3" />
                            <Placeholder className="col-6" /> <Placeholder className="col-5" />
                            <Placeholder className="col-2" /> <Placeholder className="col-3" /> <Placeholder className="col-5" />
                            <Placeholder className="col-4" /> <Placeholder className="col-3" />
                          </Placeholder>
                          <Placeholder as={Button} animation="wave" className='px-4 py-2 btn-danger'><b>Pročitaj više</b></Placeholder>
                        </Col>
                      )))
                    }
                  </>
                }
              </Row>
              <Row className='pt-3 pb-3'>
                <Nav>
                  <Pagination>{PaginationBTNs}</Pagination>
                </Nav>
              </Row>
            </Col>
        }
        <Col className='col-12 col-md-5 ps-0 ps-md-4'>
          <Container className='pb-3 pt-3 ms-0 ps-0'><h4><b>Zadnji postovi</b></h4></Container>
          <Container className="pb-3 ms-0 ps-0 border-bottom">
            {latestPosts.length > 0 ?
              latestPosts.map(post => (
                <Row key={post.id} className="pb-3">
                  <Col className="col-5">
                    <Link to={"/novosti?postID=" + post.id}>
                      <Image src={(post && post._embedded && post._embedded['wp:featuredmedia']) ?
                        post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
                        :
                        Global.imagePlaceholder} className='border rounded object-fit-cover w-100' height={120} />
                    </Link>
                  </Col>
                  <Col className="col-7 px-0 d-flex align-items-center">
                    <Container className='pe-0'>
                      <Row>
                        <div className="px-0" dangerouslySetInnerHTML={{ __html: (sanitizer(post.content.rendered)).substring(0, 100) + "..." }} />
                      </Row>
                      <Row className="text-secondary">
                        {new Date(post.date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              ))
              :
              //SKELETON / PLACEHOLDER for all latest posts
              Array.from({ length: 5 }, ((ele, index) => (
                <Row key={index} className="pb-3">
                  <Col className="col-5">
                    <Placeholder as={Card.Img} animation="wave" height={120} className='border rounded object-fit-cover w-100' />
                  </Col>
                  <Col className="col-7 px-0 d-flex align-items-center">
                    <Container className='pe-0'>
                      <Row>
                        <Placeholder as={Card.Text} animation="wave" className='px-0' >
                          <Placeholder className="col-2" /> <Placeholder className="col-5" /> <Placeholder className="col-3" />
                          <Placeholder className="col-4" /> <Placeholder className="col-4" /> <Placeholder className="col-3" />
                          <Placeholder className="col-3" /> <Placeholder className="col-4" />
                        </Placeholder>
                      </Row>
                      <Row className="text-secondary">
                        <Placeholder as={Card.Text} animation="wave" className='ps-0'>
                          <Placeholder className="col-5" />
                        </Placeholder>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              )))
            }
          </Container>
          <Container className='pt-4 pb-3 ms-0 ps-0'>
            <h4><b>Kategorije</b></h4>
          </Container>
          <Container className='ms-0 ps-0'>
            <Nav>
              <ul className='px-3'>
                {categories.length > 0 ?
                  categories.map(kate => (
                    kate.count > 0 ?
                      <Nav.Link key={kate.id} className='navbar-text' href={"/novosti?categoryId=" + kate.id}>
                        <li>{kate.name}</li>
                      </Nav.Link> 
                      :
                      <></>
                  ))
                  :
                  <Placeholder as={Card.Text} animation="wave" className='ps-0'>
                    <Placeholder className="col-5" />
                  </Placeholder>
                }
              </ul>
            </Nav>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}
export default News
