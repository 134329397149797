import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Social from "./Social";
import ExternalLinks from "./ExternalLinks";

const Footer = () => {
  return (
    <>
      <ExternalLinks />
      <Social centered="true" />
      <Container className="mx-auto pb-5 border-bottom border-bottom" fluid>
        <Row className="py-1">
          <Col className="col-auto mx-auto">
            <span>© 2024 DVD Neapolis</span>
          </Col>
        </Row>
        <Row className="py-1">
          <Col className="col-auto mx-auto">
            <span>Made by</span><Link to={'http://www.inovigrad.com/'} target="_blank" className="px-1 text-danger">iNovigrad</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Footer

