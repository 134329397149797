import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { Alert, Container } from 'react-bootstrap';
import Global from 'config/Global';

const mapContainerStyle = {
  height: '300px',
};
const center = {
  lat: 45.31944104788519, // default latitude
  lng: 13.569805258178173, // default longitude 45.31944104788519, 13.569805258178173
};

const Google_map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: Global.APIkey,
  });

  if (loadError) {
    return <Container>
      <Alert show={true} variant="danger">
        <Alert.Heading>GREŠKA PRI UČITAVANJU MAPE!</Alert.Heading>
      </Alert>
    </Container>;
  }

  if (!isLoaded) {
    return <Container>Mapa se učitava...</Container>;
  }

  return (
    <Container className='pe-0'>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </Container>
  );
};

export default Google_map;