import React from "react";
import Headline from "./Headline";
import LatestPosts from "./LatestPosts";
import { Image } from 'react-bootstrap';
import Naslovna from '../images/naslovna.jpg';

const Homepage = () => {
  document.title = "Naslovna - DVD Neapolis"
  return (
    <>
      <Image src={Naslovna} className="w-100 py-3" height={500} rounded style={{ objectFit: 'cover' }} />
      <Headline />
      <LatestPosts />
    </>
  );
}
export default Homepage