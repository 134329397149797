import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from 'components/Homepage';
import NotFound from 'components/NotFound';
import AboutUs from 'components/AboutUs';
import News from 'components/News';
import Gallery from 'components/Gallery';
import Contact from 'components/Contact';

const App = () => {
  return (
    <BrowserRouter>
      <Container className='px-4 col-auto col-xxl-10 col-xl-10 col-lg-auto' fluid>
        <Header />
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="o-nama" element={<AboutUs />} />
          <Route path="novosti" element={<News />} />
          <Route path="galerija" element={<Gallery />} />
          <Route path="kontakt" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Container>
    </BrowserRouter>
  )
}
export default App

