import React from "react";
import { Breadcrumb, Card, Placeholder } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Links from "./Links";

/*subLinks - Input array of objects {displayName [string], Link [string]}
*	displayName [string] -> Will display the given name on the UI
*	Link [string]	-> Will be used for creating the link/anchor 
*
* NOTE: Highest breadcrumb is current page, lowest is array's (subLinks) last index
* NOTE2: If wanted to display skeleton/placeholder while breadcrumb gets the real data, give displayName as empty string
*/
function Breadcrumbs({ subLinks }) {
	const location = useLocation();
	const { pathname } = location;
	const currentPage = pathname === "/" ? "/" : pathname.substring(1, pathname.length);

	return (
		<Breadcrumb className='py-3 ps-0 col-0'>
			<Breadcrumb.Item key={"bc-0"} href="/">Naslovna</Breadcrumb.Item>
			{
				//Display current page breadcrumb based on Links.jsx pagesLinks list.
				Object.keys(Links.pagesLinks).map(ele => {
					if (currentPage === Links.pagesLinks[ele].link) {
						return (
							<Breadcrumb.Item
								key={Links.pagesLinks[ele].key}
								href={Links.pagesLinks[ele].link}
								active={subLinks.length === 0}
								className='text-capitalize'>
								{Links.pagesLinks[ele].text.toLowerCase()}
							</Breadcrumb.Item>
						);
					}
					return null;
				})
			}
			{
				//Display breadcrumbs for pages based on input array 'subLinks' of this function
				//Highest breadcrumb is current page, lowest is input array's last index
				(subLinks && subLinks.map((displayName, Link, index) => {
					return (
						displayName.length !== 0 ?
							<Breadcrumb.Item key={"bc-" + index} href={Link} active={subLinks.length !== index}>{
								displayName[0].toUpperCase() + displayName.slice(1)
							}</Breadcrumb.Item>
							:
							<Placeholder as={Card.Text} animation="wave" className='ps-2 col'>
								{"/ "}<Placeholder className='col-1' />
							</Placeholder>
					);
				}))
			}
		</Breadcrumb>
	);
}
export default Breadcrumbs