import { Image, Container, Col, Row, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Social from './Social';
import Links from "./Links";
import logo3 from '../images/logo3.jpg';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const currentPage = pathname === "/" ? "/" : pathname.substring(1, pathname.length);

  return (
    <Container className='border-bottom bg-white sticky-top px-0 py-1' fluid>
      <Row className="align-items-center bg-white">
        <Col className="col-2 d-xl-none d-block">
          <Navbar expand={'xxl xl lg md sm'}>
            <Navbar.Toggle />
            <Navbar.Offcanvas placement="top" className="h-0">
              <Offcanvas.Header className="border-bottom d-flex py-2">
                <Offcanvas.Header className='col-1 m-0 py-2' closeButton />
                <Container className='col-10 d-flex justify-content-center'>
                  <Image alt="DVD Neapolis logo" src={logo3} height={60} className="w-auto" />
                </Container>
                <Container className='col-1 m-0'></Container>
              </Offcanvas.Header>
              <Offcanvas.Header className='d-flex justify-content-center border-bottom'>
                <Offcanvas.Title className='fw-bold'>
                  MENI
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='p-1 overflow-visible bg-white'>
                <Container className='border-bottom' fluid>
                  <Row>
                    {
                      Object.keys(Links.pagesLinks).map(ele => {
                        return (
                          <Nav.Link key={Links.pagesLinks[ele].key} href={Links.pagesLinks[ele].link}
                            className={"d-flex justify-content-center my-3 fw-bold " + (currentPage === Links.pagesLinks[ele].link ? "text-danger" : "")}>
                            {Links.pagesLinks[ele].text}</Nav.Link>
                        )
                      })
                    }
                  </Row>
                </Container>
                <Social centered="true" />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        </Col>
        <Col className="col-auto mx-auto ps-3 me-auto mx-xl-0">
          <Nav.Link href={Links.pagesLinks.homepage.link}>
            <Image alt="DVD Neapolis logo" src={logo3} height={60} />
          </Nav.Link>
        </Col>
        <Col className="col-auto p-1 ms-auto d-xl-block d-none">
          <Nav>
            {
              Object.keys(Links.pagesLinks).map(ele => {
                return (
                  <Nav.Link key={Links.pagesLinks[ele].key} href={Links.pagesLinks[ele].link}
                    className={currentPage === Links.pagesLinks[ele].link ? "text-danger" : "text-body"}>
                    {Links.pagesLinks[ele].text}</Nav.Link>
                )
              })
            }
          </Nav>
        </Col>
        <Col className='col-2 col-xl-auto pe-0'>
          <Social centered="false" />
        </Col>
      </Row>
    </Container>
  );
}

export default Header