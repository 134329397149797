import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import { Col, Row, Container, Alert, Collapse, Spinner } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import GoogleMap from './Google_map';
import Global from 'config/Global';
import Breadcrumbs from './Breadcrumbs';

const Contact = () => {
  document.title = "Kontakt - DVD Neapolis";

  const form = useRef();
  const [show, showForm] = useState(0);
  let flag = true;  // USE TO PREVENT RE-SEND

  const posaljiPoruku = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get('password')) { return; }
    if (flag) {
      flag = false;
      showForm(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      emailjs.sendForm(Global.emailServiceID, Global.emailTemplateID, form.current, Global.emailPublicKey)
        .then((result) => {
          showForm(2);
        }, (error) => {
          showForm(3);
        });
    }
  };

  return (
    <Container className="py-4" fluid>
      <Row>
        <Breadcrumbs subLinks={[]} />
      </Row>
      <Row>
        <Container className='py-2' fluid>
          <Row className='h1 fw-bold' >Kontakt</Row>
          <Row>Tu smo da odgovorimo na vaša pitanja, primimo povratne informacije i uspostavimo vezu.</Row>
          <Row className='py-2'>Javite nam se putem dostupnih kontaktnih podataka ili popunite obrazac ispod.</Row>
        </Container>
      </Row>
      <Row className='pt-3 me-0'>
        <Col className='col-12 col-xl-6 px-0 pe-xl-4 pb-5'>
          <Collapse in={show===1 ? true : false}>
            <Col>
              <Alert variant="primary" >
                <Alert.Heading className='d-flex align-items-center'>
                  <Spinner animation="grow" variant="primary"/>
                  <Col className='px-2'>SLANJE PORUKE U TIJEKU.....</Col>
                  <Spinner animation="grow" variant="primary"/> 
                </Alert.Heading>
              </Alert>
            </Col>
          </Collapse>
          <Collapse in={show===2 ? true : false}>
          <Col>
              <Alert variant="success">
                <Alert.Heading>PORUKA USPJEŠNO POSLANA!</Alert.Heading>
              </Alert>
            </Col>
          </Collapse>
          <Collapse in={show===3 ? true : false}>
            <Col>
              <Alert variant="danger">
                <Alert.Heading>GREŠKA PRI SLANJU PORUKE!</Alert.Heading>
              </Alert>
            </Col>
          </Collapse>
          <Collapse in={show===0 ? true : false}>
            <Form ref={form} onSubmit={posaljiPoruku}>
              <Row>
                <Col className='col-12 col-xl-6 mb-3 pe-0'>
                  <Form.Group>
                    <Form.Label>Ime</Form.Label>
                    <Form.Control type="text" name="ime" placeholder="Ime"  />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control type="hidden" name="password" />
                  </Form.Group>
                </Col>
                <Col className='col-12 col-xl-6 mb-3 mb-xl-0 pe-0'>
                  <Form.Group>
                    <Form.Label>Prezime</Form.Label>
                    <Form.Control type="text" name="prezime" placeholder="Prezime"  />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Form.Group className='pe-0'>
                  <Form.Label>Email adresa</Form.Label>
                  <Form.Control type="email" name="email" placeholder="ime@domena.com"  />
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group className='pe-0'>
                  <Form.Label>Poruka</Form.Label>
                  <Form.Control as="textarea" name="poruka" rows={8}  />
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Button className="px-4 py-2 btn-danger" type="submit"><b>Pošalji</b></Button>
                </Col>
              </Row>
            </Form>
          </Collapse>
        </Col>
        <Col className='col-0 px-0 ps-xl-4'>
          <Row>
            <p>Gdje se nalazimo:</p>
          </Row>
          <Row className='py-2'>
            <GoogleMap />
          </Row>
          <Row className='pt-4'>
            <p><b>DVD-NEAPOLIS / DOBROVOLJNO VATROGASNO DRUŠTVO NEAPOLIS NOVIGRAD</b></p>
            <p>Adresa: Ulica domovinskih žrtava 1</p>
            <p>Poštanski broj: 52466 Novigrad</p>
          </Row>
        </Col>
      </Row>
    </Container>
  );

}
export default Contact
