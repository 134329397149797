import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Placeholder, Card } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import { GetPageOnama } from 'api/API';
import DOMPurify from 'dompurify';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { Link } from 'react-router-dom';

const AboutUs = () => {
	const [pageContent, setPageContent] = useState(null);
	const [pageHTML, setPageHTML] = useState(null);
	const [imagePodrucje, setImagePodrucje] = useState(null);
	const sanitizer = DOMPurify.sanitize;

	useEffect(() => {
		async function loadData() {
			const fetchedPage = await GetPageOnama();
			setPageContent(fetchedPage);
		}
		loadData();
	}, []);

	useEffect(() => {
		if (pageContent && pageContent.length > 0) {
			document.title = pageContent[0].yoast_head_json.title;
			const pageDoc = new DOMParser().parseFromString(pageContent[0].content.rendered, 'text/html');
			const imagePodrucje = pageDoc.getElementById('slikapodrucje').outerHTML;
			setImagePodrucje(imagePodrucje);
			pageDoc.getElementById('slikapodrucje').remove();
			setPageHTML(pageDoc.body.innerHTML);
		}
	}, [pageContent]);

	const onInit = () => {};

	return (
		<Container fluid className='px-0'>
			<Row className='mx-0 mt-4'>
				<Breadcrumbs subLinks={[]} />
			</Row>
			{pageContent && pageContent.length > 0 ? (
				<>
					<h1 className='py-2'><b>{pageContent[0].title.rendered}</b></h1>
					<Container fluid className='px-0 mx-0'>
						<Row className='px-0 mx-0'>
							<Col xs={12} className='px-0 mx-0'>
								<Image
									src={pageContent[0]._embedded['wp:featuredmedia'][0].source_url}
									alt={pageContent[0]._embedded['wp:featuredmedia'][0].alt_text}
									className="img-fluid w-100 py-4"
									style={{ objectFit: 'cover', height: '400px' }}
								/>
							</Col>
						</Row>
					</Container>
					{(pageHTML && pageHTML.length > 0 && imagePodrucje && imagePodrucje.length > 0) ?
						<>
							<div dangerouslySetInnerHTML={{ __html: sanitizer(pageHTML) }} />
							<Container className='.lg-container px-0'>
								<LightGallery
									onInit={onInit}
									speed={500}
									plugins={[lgThumbnail, lgZoom]}
									selector=".lg-item"
									elementClassNames="px-0 mx-0"
								>
									<Link to={imagePodrucje} data-src={imagePodrucje} className="lg-item">
										<div dangerouslySetInnerHTML={{ __html: sanitizer(imagePodrucje) }} />
									</Link>
								</LightGallery>
							</Container>
						</>
						:
						<></>
					}
				</>
			) : (
				<Container fluid className="px-0 mx-0 mt-3">
					<Placeholder as={Card.Title} className="pt-2 pb-3" animation="wave">
						<Placeholder xs={2} className="h2" />
					</Placeholder>
					<Placeholder as={Row} animation="glow" className="px-0 mx-0 mt-3 pb-3">
						<Placeholder xs={12} style={{ height: '400px' }} />
					</Placeholder>
					<Placeholder as="div" animation="wave" className="mt-2 mb-5">
						<Placeholder xs={12} />
						<Placeholder xs={11} />
						<Placeholder xs={12} />
						<Placeholder xs={4} />
						<Placeholder xs={12} />
						<Placeholder xs={12} />
						<Placeholder xs={8} />
					</Placeholder>
				</Container>
			)}
		</Container>
	);
};

export default AboutUs;

