import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ndgrb from '../images/ndgrb.png';
import HVZ from '../images/HVZ.jpg';
import JVP_Umag from '../images/JVP-Umag.jpg';

const Links = () => {
  return (
    <Container className="py-4 col-12 border-bottom" fluid>
      <Row>
        <Col className="d-flex mx-auto col-12 col-md-10 col-lg-8">

          <Link to={'https://vatrogasciumag.hr'} target="_blank" className='d-flex flex-column col-4 px-3 px-md-5 py-3'>
            <Image alt="Logo JVP-a Umag" src={JVP_Umag} height={95} className='object-fit-contain' />
            <label className="text-center text-body">JVP Umag</label>
          </Link>
          <Link to={'https://novigrad.hr'} target="_blank" className='d-flex flex-column col-4 px-3 px-md-5 py-3'>
            <Image alt="Grb grada Novigrada" src={ndgrb} height={95} className='object-fit-contain' />
            <label className="text-center text-body">Grad Novigrad</label>
          </Link>
          <Link to={'https://hvz.gov.hr'} target="_blank" className='d-flex flex-column col-4 px-3 px-md-5 py-3'>
            <Image alt="Hrvatska vatrogasna zajednica" src={HVZ} height={95} className='object-fit-contain' />
            <label className="text-center text-body">HVZ</label>
          </Link>
        </Col>
      </Row>

    </Container>
  );
}
export default Links