import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Global from 'config/Global';
import DOMPurify from 'dompurify';
import { GetPosts } from 'api/API';

//HOMEPAGE COMPONENT - Latest News
const LatestPosts = () => {
  const [posts, setPosts] = useState([]);
  const sanitizer = DOMPurify.sanitize;

  useEffect(() => {
    async function loadData() {
      const fetchedPosts = await GetPosts({ quantity: 6, order: true, category_ID: null, page: 0 });
      setPosts(fetchedPosts.data);
    }

    loadData();
  }, []);

  return (
    <Container className="pt-4 px-0" fluid>
      <Row>
        <Col className="col-auto mx-auto py-2">
          <h3>ZADNJE NOVOSTI</h3>
        </Col>
      </Row>
      <Row className='col-12 mx-0 px-0'>
        {posts && posts.map((post, index) => (
          <Col key={post.id} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
            <Link to={"/novosti?postID=" + post.id}>
              <Image src={(post && post._embedded && post._embedded['wp:featuredmedia']) ?
                post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url :
                Global.imagePlaceholder} className='border rounded object-fit-cover w-100' height={200} />
            </Link>
            <h5 className='py-2'><b>{post.title.rendered}</b></h5>
            <p className="text-secondary">{post._embedded['wp:term'][0][0].name + " | " +
              new Date(post.date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
            <div dangerouslySetInnerHTML={{ __html: (sanitizer(post.content.rendered)).substring(0, 250) + "..." }} />
            <Link to={"/novosti?postID=" + post.id}>
              <Button className="px-4 py-2 btn-danger"><b>{"Pročitaj više"}</b></Button>
            </Link>
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="col-auto mx-auto pt-4 pb-4">
          <Link to={"/novosti"}>
            <Button className="px-4 py-2 btn-danger"><b>{"Vidi sve novosti"}</b></Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
export default LatestPosts