const Links = () => { }

//Links to and page names for all website pages
//USAGE: Menu, Breadcrumb,...etc.
Links.pagesLinks = {
  homepage: { key: 1, link: '/', text: 'NASLOVNA' },
  oNama: { key: 2, link: 'o-nama', text: 'O NAMA' },
  novosti: { key: 3, link: 'novosti', text: 'NOVOSTI' },
  galerija: { key: 4, link: 'galerija', text: 'GALERIJA' },
  kontakt: { key: 5, link: 'kontakt', text: 'KONTAKT' }
};

export default Links;